import React from "react";

const FullWidthImage4 = () => {
  return (
    <img
      alt='altImage goes here :>'
      src={"/img/3-new.jpg"}
      // imgPosition={""}
      className='w-screen'
    />
  );
};

export default FullWidthImage4;
