import React from 'react'

const BenefitsPerks = () => {
  return (
    <>
      <img
        src='/img/benefitsperks.png'
        alt='step1'
        className='w-full h-full'
      />
    </>
  );
}

export default BenefitsPerks