import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import JobRoll from "../components/JobRoll";
import FullWidthImage from "../components/FullWidthImage";
import DotMatrix from "../components/DotMatrix";
import FullWidthImage4 from "../components/FullWidthImage4";
import HeaderBanner1 from "../components/HeaderBanner1";
import BenefitsPerks from "../components/BenefitsPerks";

// eslint-disable-next-line
export const CareersPageTemplate = ({ title, image }) => {
  const heroImage = getImage(image) || image;

  return (
    <>
      <FullWidthImage img={heroImage} title={title} />
      <HeaderBanner1 />
      <section className='mx-auto my-10'>
        <div className='w-9/12 mx-auto mb-6 pl-10 shadow-3xl bg-gray bg-opacity-40 rounded-lg p-4'>
          <span className='relative text-4xl'>
            Working at Bristol Place means...
          </span>
        </div>
        <h3 className='w-9/12 mx-auto text-xl py-4'>
          Working at Bristol Place means you get to make a difference in the
          lives of others. We support seniors and individuals with disabilities
          and their families, connecting them to the services and resources they
          can use to live their best lives. We offer creative, person- centered
          solutions that allow families and individuals to know and choose the
          options that best fit their needs. Being part of our team means
          working alongside enthusiastic individuals to deliver outstanding
          support that strengthens our communities.
        </h3>
        <div className='pb-4'>
          <DotMatrix />
        </div>
        <div>
          <div className="py-8 mb-8 bg-bristol-blue-2 text-white shadow-3xl">
            <div className="w-9/12 mx-auto">
              <div className='text-4xl text-center mx-auto'>
                Because you're worth it...
              </div>
              <p className='m-4 text-xl'>
                Working in the human service field is very rewarding, but can also be stressful. At Bristol Place Corporation we value a work/life balance and appreciate the need to take time to rejuvenate ourselves to keep on providing quality services. Here are some of the things we have to offer:
              </p>
            </div>
          </div>
        </div>
        <div className="w-9/12 mx-auto">
          <BenefitsPerks />
        </div>
        <div className='pt-4'>
          <DotMatrix />
        </div>
        <JobRoll />
      </section>
      <HeaderBanner1 />
      <FullWidthImage4 />
    </>
  );
};

CareersPageTemplate.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
};

const CareersPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout className='font-sans'>
      <CareersPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
      />
    </Layout>
  );
};

CareersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CareersPage;

export const pageQuery = graphql`
  query CareersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "careers-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
      }
    }
  }
`;
